import React from 'react';
import { graphql } from 'gatsby';
import ServicesTemplate from '@templates/Services';

export default function Services() {
  return <ServicesTemplate />;
}

export const query = graphql`
  fragment Documents on FileConnection {
    edges {
      node {
        id
        publicURL
        name
        children {
          ... on File {
            id
            name
            fields {
              pageNum
            }
            page: childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }

            thumbnail: childImageSharp {
              gatsbyImageData(
                height: 80
                layout: FIXED
                placeholder: NONE
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`;
