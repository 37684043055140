import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Service from '../Service';

export default function NPPO() {
  const files = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { eq: "pdf" }
          relativeDirectory: { regex: "/NPPO/" }
        }
      ) {
        ...Documents
      }
    }
  `);

  return (
    <Service
      title="Native Plant Preservation Plans"
      text={`
        Each municipality in the Southern Arizona and Phoenix metro area takes a different approach to the protection of native plants.

        We understand these differences and create NPPO plans with an eye on the successful completion of our client’s projects. We have completed thousands of NPPO’s covering more than 50,000 acres of land.
      `}
      contentPosition="right"
      contentWidth={400}
      icon="NPPO"
      iconOffsetLeft={-4}
      files={files}
      inlineViewer
      blueBg
    />
  );
}
