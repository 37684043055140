import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ImageRenderer from './ImageRenderer';
import Thumbnails from './Thumbnails';
import TopBar from './TopBar';
import Spinner from '@components/Spinner';
import { isBrowser } from '@helpers';
import classNames from 'classnames';
import * as styles from './DocumentViewer.module.scss';
import FocusLock from 'react-focus-lock';
import { RemoveScroll } from 'react-remove-scroll';

const PDFRenderer = React.lazy(() => import('./PDFRenderer'));

export default function DocumentViewer({
  className,
  files: _files,
  isModalOnly,
  isOpen,
  setIsOpen
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const files = useMemo(() => getFiles(_files), [_files]);
  const [currentFile, _setCurrentFile] = useState(files?.[0]);

  const setCurrentFile = useCallback(
    id => {
      _setCurrentFile(currentFile => {
        if (currentFile?.id === id) {
          return currentFile;
        }

        setHasError(false);
        return files.find(f => f.id === id);
      });
    },
    [files]
  );

  useEffect(() => {
    if (hasError) {
      setIsLoading(false);
    }
  }, [hasError]);

  useEffect(() => {
    document.activeElement?.blur();

    if (setIsOpen && !isExpanded) {
      setIsOpen(false);
    }

    setTimeout(() => {
      document.activeElement?.blur();
    }, 100);
  }, [isExpanded, setIsOpen]);

  useEffect(() => {
    if (isOpen) {
      setIsExpanded(true);
    }
  }, [isOpen]);

  useEffect(() => {
    const handler = e => {
      if (e.key === 'Escape' || e.key === 'Esc') {
        if (setIsOpen) {
          setIsOpen(false);
        }

        setIsExpanded(false);
      }
    };

    window.addEventListener('keydown', handler);

    return () => window.removeEventListener('keydown', handler);
  }, [setIsOpen]);

  return !currentFile?.url || (isModalOnly && !isOpen) ? null : (
    <RemoveScroll
      enabled={isExpanded}
      allowPinchZoom
      className={classNames('viewer', styles.wrapper, className, {
        [styles.loading]: isLoading,
        [styles.error]: hasError,
        [styles.expanded]: isExpanded
      })}
    >
      <FocusLock
        // autoFocus={false}
        disabled={!isExpanded}
        className={styles.content}
      >
        {!hasError && (
          <TopBar
            file={currentFile}
            isLoading={isLoading}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            setIsOpen={setIsOpen}
          />
        )}

        <div className={styles.documentWrapper}>
          {isLoading && !hasError && (
            <Spinner
              color="#fff"
              trackColor="rgba(255, 255, 255, 0.08)"
              isCentered
            />
          )}

          {isBrowser() && isExpanded && (
            <React.Suspense fallback={null}>
              <PDFRenderer
                file={currentFile}
                setIsLoading={setIsLoading}
                setHasError={setHasError}
              />
            </React.Suspense>
          )}

          {!isModalOnly && (
            <ImageRenderer
              file={currentFile}
              isExpanded={isExpanded}
              setIsLoading={setIsLoading}
              setHasError={setHasError}
            />
          )}
        </div>

        {hasError && (
          <div className={styles.errorWrapper}>
            Failed to load PDF document
          </div>
        )}

        <Thumbnails
          files={files}
          currentFile={currentFile}
          setCurrentFile={setCurrentFile}
        />
      </FocusLock>
    </RemoveScroll>
  );
}

function getFiles(files) {
  if (!files || (typeof files !== 'object' && typeof files !== 'string')) {
    return null;
  }

  if (typeof file === 'string' || Array.isArray(files)) {
    return [files].flat();
  }

  return (
    files.allFile?.edges?.map(
      ({ node: { publicURL: url, id, name, children } }) => {
        const images = children?.sort((a, b) => {
          return a.fields.pageNum - b.fields.pageNum;
        });

        const pageImages = images.map(p => p.page);

        return {
          id,
          url,
          name,
          pageImages,
          thumbnail: images?.[0].thumbnail
        };
      }
    ) ?? null
  );
}
