import React, { useState, useCallback } from 'react';
import Layout from '@layout';
import Hero from './Hero';
import LandscapePlans from './LandscapePlans';
import NPPO from './NPPO';
import IrrigationPlans from './IrrigationPlans';
import WaterHarvesting from './WaterHarvesting';
import RHMP from './RHMP';
import EndangeredSpeciesSearch from './EndangeredSpeciesSearch';
import NativePlantInventories from './NativePlantInventories';
import RezoningSupport from './RezoningSupport';
import * as styles from './Services.module.scss';
import classNames from 'classnames';

export const ServicesContext = React.createContext([]);

export default function Services() {
  const [services, setServices] = useState([]);

  const setService = useCallback(({ id, ...data }) => {
    if (!id) {
      return;
    }

    setServices(prevServices => {
      const services = [...prevServices];
      const service = services.find(s => s.id === id);

      if (service) {
        Object.assign(service, data);
      } else {
        services.push({ id, ...data });
      }

      return services;
    });
  }, []);

  return (
    <Layout title="Services">
      <ServicesContext.Provider value={setService}>
        <Hero services={services} />
        <div className={classNames(styles.wrapper, 'dot-section')}>
          <LandscapePlans />
          <NPPO />
          <IrrigationPlans />
          <WaterHarvesting />
          <RHMP />
          <EndangeredSpeciesSearch />
          <NativePlantInventories />
          <RezoningSupport />
        </div>
      </ServicesContext.Provider>
    </Layout>
  );
}
