import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import classNames from 'classnames';
import * as styles from './DocumentViewer.module.scss';

export default function ThumbnailWrapper({
  files,
  currentFile,
  setCurrentFile
}) {
  return (
    files?.length && (
      <ul className={styles.thumbnailWrapper}>
        {files.map(
          ({ id, thumbnail, ...file }) =>
            thumbnail && (
              <Thumbnail
                key={id}
                id={id}
                isCurrent={id === currentFile.id}
                image={getImage(thumbnail)}
                setCurrentFile={setCurrentFile}
                {...file}
              />
            )
        )}
      </ul>
    )
  );
}

const Thumbnail = React.memo(
  ({ id, name, image, isCurrent, setCurrentFile }) => {
    return (
      <li>
        <button
          aria-label={`View PDF: ${name}`}
          className={classNames(styles.thumbnail, {
            [styles.current]: isCurrent
          })}
          onClick={() => setCurrentFile(id)}
        >
          <GatsbyImage
            image={image}
            formats={['auto', 'webp', 'avif']}
            placeholder="none"
            alt=""
          />
        </button>
      </li>
    );
  }
);
