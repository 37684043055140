import React from 'react';
import Service from '../Service';
import { useStaticQuery, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './LandscapePlans.module.scss';

export default function LandscapePlans() {
  const files = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { eq: "pdf" }
          relativeDirectory: { regex: "/LandscapePlans/" }
        }
      ) {
        ...Documents
      }
    }
  `);

  return (
    <Service
      title="Landscape Plans"
      className={styles.wrapper}
      graphicColumnClassName={styles.graphicWrapper}
      icon="LandscapePlans"
      listIconSize={28}
      mobileIconSize={45}
      text={`
        We provide creative design solutions and plans that are code compliant with the governing municipalities.
        
        Landscapes for bufferyards, parking lots, detention basins, amenity areas, native plant compliance and riparian compliance are crafted together into a good looking, cost efficient landscape design.
      `}
      contentPosition="left"
      files={files}
    >
      <StaticImage
        src="madera.png"
        alt="Landscape Drawing of Madera Highland Park"
        layout="constrained"
        height={650}
        objectFit="cover"
        objectPosition="left top"
        quality={35}
        placeholder="none"
        formats={['auto', 'webp', 'avif']}
        className={styles.graphic}
      />
    </Service>
  );
}
