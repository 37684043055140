import React from 'react';
import Service from '../Service';
import Sprinkler from './sprinkler.svg';
import { useStaticQuery, graphql } from 'gatsby';
import * as styles from './IrrigationPlans.module.scss';

export default function IrrigationPlan() {
  const files = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { eq: "pdf" }
          relativeDirectory: { regex: "/IrrigationPlans/" }
        }
      ) {
        ...Documents
      }
    }
  `);

  return (
    <Service
      title="Irrigation Plans"
      className={styles.wrapper}
      graphicColumnClassName={styles.graphicWrapper}
      text={`
        We are experts at providing water efficient irrigation design in conjunction with our landscape plans.
        
        These plans are compliant with Pima County Water Conservation Requirements and City of Tucson Commercial Water Harvesting Standards.
      `}
      contentWidth={485}
      contentColumnOffset={0}
      contentPosition="left"
      icon="IrrigationPlans"
      iconSize={78}
      mobileIconSize={58}
      listIconSize={36}
      listIconOffsetLeft={-2}
      iconClassName={styles.icon}
      files={files}
    >
      <Sprinkler className={styles.graphic} />
    </Service>
  );
}
