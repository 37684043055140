import React, { useEffect, useMemo, useRef, useCallback } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import * as styles from './DocumentViewer.module.scss';
import classNames from 'classnames';

export default React.memo(function ImageRenderer({
  file,
  setIsLoading: _setIsLoading,
  isExpanded,
  setHasError
}) {
  const ref = useRef(null);
  const loadingTimer = useRef(null);
  const setIsLoading = useCallback(
    isLoading => {
      clearTimeout(loadingTimer.current);

      if (!isExpanded) {
        _setIsLoading(isLoading);
      }
    },
    [isExpanded, _setIsLoading]
  );

  const pages = useMemo(() => {
    const images = file?.pageImages;

    return !images
      ? null
      : images.map((image, index) => {
        const props = {
          image: getImage(image),
          className: styles.pageImage,
          formats: ['auto', 'webp', 'avif'],
          layout: 'fullWidth',
          placeholder: 'none',
          alt: '',
          onError: () => setHasError(true)
        };

        if (index === 0) {
          props.onLoadStart = () => setIsLoading(true);
          props.onLoad = () => setIsLoading(false);
        }

        return (
          <div
            key={index}
            className={styles.page}
          >
            <GatsbyImage {...props} />
          </div>
        );
      });
  }, [file, setIsLoading, setHasError]);

  useEffect(() => {
    clearTimeout(loadingTimer.current);

    if (ref.current && file) {
      setIsLoading(true);

      loadingTimer.current = setTimeout(() => {
        const img = ref.current.querySelector('img');

        if (img?.complete) {
          setIsLoading(false);
          setHasError(false);
        }
      }, 300);
    } else {
      setIsLoading(true);
    }

    return () => clearTimeout(loadingTimer.current);
  }, [file, setIsLoading, setHasError]);

  useEffect(() => {
    if (isExpanded) {
      clearTimeout(loadingTimer.current);
    }
  }, [isExpanded]);

  return (
    <div
      className={classNames(styles.document, styles.imageRenderer)}
      ref={ref}
    >
      <div className={styles.zoomWrapper}>{pages}</div>
    </div>
  );
});
