import React, { useMemo } from 'react';
import { Section, Content } from '@layout';
import Corner from '@components/Corner';
import { H1 } from '@headline';
import { Button, ButtonsWrapper } from '@button';
import Border from './border.svg';
import { StaticImage } from 'gatsby-plugin-image';
import useWindowSize from '@hooks/useWindowSize';
import * as styles from './Hero.module.scss';
import * as Icon from '../icons';
import { isSSR } from '@helpers';
import classNames from 'classnames';
import { uniqBy } from 'lodash';

export default function Hero({ services }) {
  return (
    <Section
      theme="dark"
      className={styles.wrapper}
      noPadding
    >
      <Content className={styles.contentWrapper}>
        <div className={classNames(styles.content, 'section-padding')}>
          <H1 pretitle="Our Services">
            Full service andscape architecture
          </H1>
          <div className={styles.textWrapper}>
            <Corner
              side="topRight"
              color="#fff"
              dashLong="27"
              dashShort="7"
              dashGap="7"
              dashOffset="17"
              strokeWidth={3.2}
            />
            <div className={styles.text}>
              <p className="subtitle">
                GRS provides a full spectrum of landscape architectural
                services.
              </p>
              <p>
                From Rezoning support, through environmental entitlements
                to landscape and irrigation plans, we are experienced in
                all aspects of landscape architectural services needed to
                develop a project in Southern Arizona. We invite you to
                peruse our services and samples&nbsp;of our work.
              </p>

              <ButtonsWrapper className={styles.buttonsWrapper}>
                <Button to="/contact">Get Started</Button>
              </ButtonsWrapper>
            </div>
          </div>

          <StaticImage
            src="arizona.jpg"
            quality={85}
            className="bg-image"
            alt=""
            layout="fullWidth"
            objectFit="cover"
            loading="eager"
            webpOptions={{
              lossless: true,
              smartSubsample: true,
              reductionEffort: 6
            }}
          />
        </div>

        {services?.length && <ServicesList services={services} />}
      </Content>
    </Section>
  );
}

function ServicesList({ services }) {
  const { width: windowWidth } = useWindowSize();

  const handleClick = e => {
    if (isSSR()) {
      return;
    }

    const el = document.querySelector(e.target.hash);

    if (el) {
      e.preventDefault();
      window.scrollTo(0, el.offsetTop);
    }
  };

  const items = useMemo(() => {
    if (!services?.length) {
      return [];
    }

    const isSmallIcon = windowWidth <= 1220;
    const smallIconAdjustment = 8;

    let maxIconSize = services.reduce((prevIconSize, { iconSize }) => {
      return !iconSize || prevIconSize > iconSize
        ? prevIconSize
        : iconSize;
    }, 36);

    if (isSmallIcon) {
      maxIconSize -= smallIconAdjustment;
    }

    return uniqBy(services, 'name')?.map(
      (
        {
          name,
          anchor,
          icon,
          iconClassName,
          iconOffsetTop,
          iconOffsetLeft,
          iconSize = 32
        },
        index
      ) => {
        if (!name || !anchor) {
          return null;
        }

        const IconComponent = Icon[icon] ?? Icon.RHMP;

        if (isSmallIcon) {
          iconSize -= smallIconAdjustment;
        }

        return (
          <li key={index}>
            <a
              href={`#${anchor}`}
              className={styles.service}
              onClick={handleClick}
            >
              {IconComponent && (
                <div
                  className={styles.icon}
                  style={{ width: maxIconSize }}
                >
                  <IconComponent
                    className={iconClassName}
                    style={{
                      top: iconOffsetTop,
                      left: iconOffsetLeft
                    }}
                    width={iconSize}
                    height={iconSize}
                  />
                </div>
              )}
              <span>{name}</span>
            </a>
          </li>
        );
      }
    );
  }, [services, windowWidth]);

  return (
    <nav className={styles.nav}>
      <strong className={styles.navTitle}>Jump to a service</strong>
      <ul className={styles.list}>{items}</ul>
      <Border className={styles.border} />
    </nav>
  );
}
