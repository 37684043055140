// extracted by mini-css-extract-plugin
export const defaultColumnOffset = "40";
export const mobileWidth = "870px";
export const wrapper = "Services-module--wrapper--3aFT4";
export const service = "Services-module--service--x5QDD";
export const whiteText = "Services-module--whiteText--Xq-70";
export const blueBg = "Services-module--blueBg--2GW1p";
export const bg = "Services-module--bg--6uvax";
export const column = "Services-module--column--3oePh";
export const contentColumn = "Services-module--contentColumn--1gT_4";
export const left = "Services-module--left--3yW1R";
export const right = "Services-module--right--1DVv-";
export const graphicColumn = "Services-module--graphicColumn--KHuoQ";
export const contentWrapper = "Services-module--contentWrapper--1moJA";
export const disableBold = "Services-module--disableBold--1StTV";
export const icon = "Services-module--icon--2VNWy";
export const content = "Services-module--content--o9ar-";