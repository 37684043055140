// extracted by mini-css-extract-plugin
export const wrapper = "Hero-module--wrapper--1557-";
export const contentWrapper = "Hero-module--contentWrapper--397uG";
export const textWrapper = "Hero-module--textWrapper--1xdxi";
export const nav = "Hero-module--nav--2bVbp";
export const text = "Hero-module--text--2tffD";
export const navFade = "Hero-module--navFade--2ro1G";
export const border = "Hero-module--border--2HVdv";
export const navTitle = "Hero-module--navTitle--2Eh46";
export const list = "Hero-module--list--Ohy2Y";
export const service = "Hero-module--service--KPmuw";
export const icon = "Hero-module--icon--2oFke";
export const content = "Hero-module--content--1KSfe";
export const buttonsWrapper = "Hero-module--buttonsWrapper--FrjhV";