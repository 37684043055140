import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback
} from 'react';
import { H2 } from '@headline';
import Button from '@components/Button';
import DocumentViewer from '@components/DocumentViewer';
import * as styles from './Services.module.scss';
import classNames from 'classnames';
import * as Icon from './icons';
import useWindowSize from '@hooks/useWindowSize';
import { ServicesContext } from './Services';
import { uniqueId } from 'lodash';

const defaultColumnOffset = parseInt(styles.defaultColumnOffset);
const mobileWidth = parseInt(styles.mobileWidth);

export default function Service({
  id,
  className,
  title,
  contentColumnOffset,
  contentColumnClassName,
  graphicColumnClassName,
  content,
  contentClassName,
  contentStyle,
  contentPosition = 'left',
  mobileContentPosition,
  contentWidth,
  files,
  inlineViewer,
  children,
  backgroundImage,
  backgroundImageWrapperClassName,
  whiteText,
  blueBg,
  buttonText,
  onButtonClick,
  ...restProps
}) {
  id = generateId(id, title);

  if (!content) {
    content = (
      <ServiceContent
        id={id}
        className={contentClassName}
        title={title}
        style={contentStyle}
        width={contentWidth}
        buttonText={buttonText}
        inlineViewer={inlineViewer}
        files={files}
        onButtonClick={onButtonClick}
        {...restProps}
      />
    );
  }

  children = !inlineViewer ? children : <DocumentViewer files={files} />;

  const components = [
    <ContentColumn
      key="content"
      offset={contentColumnOffset}
      className={contentColumnClassName}
      content={content}
      position={contentPosition}
    />,
    <GraphicColumn
      key="graphic"
      className={graphicColumnClassName}
      content={children}
    />
  ];

  const { width: windowWidth } = useWindowSize();
  const isMobile = windowWidth <= mobileWidth;

  if (
    (contentPosition === 'right' && (!inlineViewer || !isMobile)) ||
    (mobileContentPosition === 'bottom' && isMobile)
  ) {
    components.reverse();
  }

  return (
    <section
      id={id}
      style={{
        backgroundImage:
          !backgroundImage || typeof backgroundImage !== 'string'
            ? null
            : `url('${backgroundImage}')`
      }}
      className={classNames(styles.service, className, {
        [styles.whiteText]: whiteText,
        [styles.blueBg]: blueBg
      })}
    >
      {backgroundImage && typeof backgroundImage !== 'string' && (
        <div
          className={classNames(
            styles.bg,
            backgroundImageWrapperClassName
          )}
        >
          {backgroundImage}
        </div>
      )}
      {components}
    </section>
  );
}

const ContentColumn = React.memo(
  ({
    offset = defaultColumnOffset,
    className,
    align = 'center',
    content,
    position
  }) => {
    const style = {};

    if (offset && !isNaN(offset) && offset !== defaultColumnOffset) {
      const operator = offset > 0 ? '-' : '+';
      style.width = `calc(50% ${operator} ${Math.abs(offset)}px)`;
    } else if (offset === 0) {
      style.width = '50%';
    }

    if (align === 'left') {
      style.justifyContent = 'flex-start';
    } else if (align === 'right') {
      style.justifyContent = 'flex-end';
    }

    return (
      <div
        style={style}
        className={classNames(
          styles.column,
          styles.contentColumn,
          position === 'right' ? styles.right : styles.left,
          className
        )}
      >
        {content}
      </div>
    );
  }
);

const GraphicColumn = React.memo(({ className, content }) => {
  return !content ? null : (
    <div
      className={classNames(
        styles.column,
        styles.graphicColumn,
        className
      )}
    >
      {content}
    </div>
  );
});

export const ServiceContent = React.memo(
  ({
    id: anchor,
    className,
    style = {},
    width,
    icon,
    iconClassName,
    iconOffsetTop,
    iconOffsetLeft,
    iconSize,
    mobileIconSize = 50,
    listIconClassName,
    listIconOffsetTop,
    listIconOffsetLeft,
    listIconSize,
    title,
    listTitle,
    titleMaxWidth,
    text,
    textClassName,
    textMaxWidth,
    boldFirstSentence = true,
    buttonText = 'View Plans',
    inlineViewer,
    files,
    onButtonClick
  }) => {
    const { width: windowWidth } = useWindowSize();
    const IconComponent = Icon[icon] ?? Icon.RHMP;

    if (!icon || icon === 'RHMP') {
      iconSize = iconSize ?? 72;
    }

    if (windowWidth <= mobileWidth) {
      iconSize = mobileIconSize;
    }

    // Add service details to list used to generate
    // anchor links in hero banner
    const setService = useContext(ServicesContext);
    const id = useRef(() => uniqueId());

    useEffect(() => {
      setService({
        id: id.current,
        name: listTitle ?? title,
        anchor,
        icon,
        iconClassName: listIconClassName,
        iconOffsetTop: listIconOffsetTop,
        iconOffsetLeft: listIconOffsetLeft,
        iconSize: listIconSize
      });
    }, [
      setService,
      title,
      listTitle,
      anchor,
      icon,
      listIconClassName,
      listIconOffsetTop,
      listIconOffsetLeft,
      listIconSize
    ]);

    if (typeof text === 'string') {
      text = text
        .split('\n')
        .map((t, index) => {
          t = t.trim();
          return !t ? null : <p key={index}>{t}</p>;
        })
        .filter(p => p);
    }

    const [plansAreExpanded, setPlansAreExpanded] = useState(false);

    const handleButtonClick = useCallback(
      e => {
        setPlansAreExpanded(true);

        if (onButtonClick) {
          onButtonClick(e);
        }
      },
      [onButtonClick]
    );

    const hasModalPlans = files && !inlineViewer;

    return !text && !title ? null : (
      <React.Fragment>
        <div
          className={classNames(
            'section-padding',
            'content',
            styles.contentWrapper,
            className,
            {
              [styles.disableBold]: !boldFirstSentence
            }
          )}
        >
          <div
            className={styles.content}
            style={{ ...style, width: width }}
          >
            {IconComponent && (
              <IconComponent
                className={classNames(styles.icon, iconClassName)}
                style={{ top: iconOffsetTop, left: iconOffsetLeft }}
                width={iconSize}
                height={iconSize}
              />
            )}
            {title && <H2 style={{ maxWidth: titleMaxWidth }}>{title}</H2>}
            {text && (
              <div
                className={textClassName}
                style={{ maxWidth: textMaxWidth }}
              >
                {text}
                {hasModalPlans && (
                  <React.Fragment>
                    <Button onClick={handleButtonClick}>
                      {buttonText}
                    </Button>
                  </React.Fragment>
                )}
              </div>
            )}
          </div>
        </div>
        {hasModalPlans && (
          <DocumentViewer
            files={files}
            isOpen={plansAreExpanded}
            setIsOpen={setPlansAreExpanded}
            isModalOnly
          />
        )}
      </React.Fragment>
    );
  }
);

function generateId(id, title) {
  return !id && title
    ? title.trim().replace(/ +/g, '-').toLowerCase()
    : id;
}
