import React from 'react';
import Service from '../Service';
import Divider from '@components/Divider';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './NativePlantInventories.module.scss';

export default function NativePlantInventories() {
  return (
    <Service
      title="Native Plant Inventories"
      text={`
        Native plant inventories are used to fulfill a broad range of local Ordinances.
            
        We use Topcon data collectors and customized software to provide fast and accurate inventory data. Our field crew has the experience to quickly gather the required data. With this experience, we can accurately assess the condition of native plants without over-committing our clients to costly salvage and mitigation.
      `}
      className={styles.wrapper}
      contentWidth={440}
      contentPosition="left"
      icon="NativePlantInventories"
      iconOffsetLeft={-3}
      listIconSize={28}
    >
      <div className={styles.graphicWrapper}>
        <StaticImage
          src="plants.png"
          className={styles.graphic}
          alt=""
          layout="constrained"
          quality={30}
          placeholder="none"
          objectFit="cover"
          objectPosition="bottom left"
          formats={['auto', 'webp', 'avif']}
          height={806}
        />
      </div>
      <Divider
        className={styles.line}
        strokeWidth={4}
        color="#142142"
      />
    </Service>
  );
}
