import React from 'react';
import Service from '../Service';
import { StaticImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';
import Rain from './rain.svg';
import * as styles from './WaterHarvesting.module.scss';

export default function WaterHarvesting() {
  const files = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { eq: "pdf" }
          relativeDirectory: { regex: "/WaterHarvesting/" }
        }
      ) {
        ...Documents
      }
    }
  `);

  return (
    <Service
      title="Water Harvesting Plans"
      text={`
        The City of Tucson has a unique Ordinance that requires the landscape architect to demonstrate that at least 50% of the required plant irrigation can come from harvested rainwater.
        
        GRS has developed unique format for these calculations that simplifies the engineering and coordination of the Water Harvesting Plans.
      `}
      className={styles.wrapper}
      contentPosition="right"
      contentWidth={440}
      icon="WaterHarvesting"
      iconSize={68}
      listIconSize={34}
      files={files}
      whiteText
      backgroundImage={
        <StaticImage
          src="water-harvesting.jpg"
          quality={78}
          alt=""
          formats={['auto', 'webp', 'avif']}
          layout="fullWidth"
          objectFit="cover"
        />
      }
    >
      <Rain className={styles.rain} />
    </Service>
  );
}
