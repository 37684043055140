import React from 'react';
import Service from '../Service';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './EndangeredSpeciesSearch.module.scss';

export default function EndangeredSpeciesSearch() {
  return (
    <Service
      title="Endangered Species Search"
      text={`
        We have two certified Burrowing Owl experts that can provide clearance of your site.
          
        If owls are found, we can help facilitate their removal and relocation. We also have expertise in conducting Pima Pineapple Cactus searches.
      `}
      contentWidth={400}
      className={styles.wrapper}
      contentColumnClassName={styles.content}
      contentPosition="right"
      icon="EndangeredSpeciesSearch"
      iconSize={65}
      iconOffsetLeft={-10}
      listIconSize={34}
      whiteText
      backgroundImage={
        <StaticImage
          src="owls.jpg"
          className={styles.graphic}
          alt=""
          formats={['auto', 'webp', 'avif']}
          layout="fullWidth"
          objectPosition="top center"
        />
      }
    />
  );
}
