import React, { useEffect, useState, useRef, useCallback } from 'react';
import * as styles from './IconButton.module.scss';
import useWindowSize from '@hooks/useWindowSize';
import classNames from 'classnames';

export default function IconButton({
  icon: Icon,
  className,
  iconClassName,
  size = 20,
  style = {},
  tooltipText,
  href,
  onClick,
  ...restProps
}) {
  const button = useRef(null);
  const tooltip = useRef(null);
  const { width: windowWidth } = useWindowSize();
  const [leftOffset, setLeftOffset] = useState(null);

  useEffect(() => {
    if (tooltip.current) {
      const padding = 12;
      const rect = button.current.getBoundingClientRect();
      const buttonWidth = rect.width;
      const tooltipWidth = tooltip.current.offsetWidth;
      const tooltipRight =
        rect.left + buttonWidth / 2 - tooltipWidth / 2 + tooltipWidth;
      const maxRight = window.innerWidth - padding;
      const leftOffset =
        tooltipRight <= maxRight ? null : maxRight - tooltipRight;

      setLeftOffset(leftOffset);
    }
  }, [windowWidth, tooltipText]);

  const [isHidden, setIsHidden] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => (isMounted.current = false);
  }, []);

  useEffect(() => {
    const handler = () => {
      setTimeout(() => {
        if (isMounted.current) {
          setIsPressed(false);
        }
      }, 100);
    };

    window.addEventListener('mouseup', handler);
    window.addEventListener('touchend', handler);

    return () => {
      window.removeEventListener('mouseup', handler);
      window.removeEventListener('touchend', handler);
    };
  }, []);

  const handleClick = useCallback(
    e => {
      if (onClick) {
        setIsHidden(true);
        onClick(e);
      }

      setIsPressed(true);

      setTimeout(() => {
        document.activeElement?.blur();
      }, 30);
    },
    [onClick]
  );

  useEffect(() => {
    let timer = null;

    const handler = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setIsHidden(false);
        document.activeElement?.blur();
      }, 200);
    };

    window.addEventListener('mouseup', handler);
    window.addEventListener('touchend', handler);

    return () => {
      clearTimeout(timer);
      window.removeEventListener('mouseup', handler);
      window.removeEventListener('touchend', handler);
    };
  }, []);

  const Tag = href ? 'a' : 'button';

  return (
    <Tag
      ref={button}
      href={href}
      className={classNames(styles.button, className, {
        [styles.pressed]: isPressed
      })}
      style={{ ...style, width: size, height: size, fontSize: size }}
      onClick={handleClick}
      {...restProps}
    >
      {Icon && <Icon className={iconClassName} />}
      {tooltipText && (
        <div
          ref={tooltip}
          className={styles.tooltip}
          style={{
            marginLeft: leftOffset,
            visibility: isHidden ? 'hidden' : null
          }}
        >
          {tooltipText}
        </div>
      )}
    </Tag>
  );
}
