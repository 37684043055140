import React from 'react';
import Service from '../Service';
import { useStaticQuery, graphql } from 'gatsby';
import * as styles from './RHMP.module.scss';
import { StaticImage } from 'gatsby-plugin-image';

export default function RHMP() {
  const files = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { eq: "pdf" }
          relativeDirectory: { regex: "/RHMP/" }
        }
      ) {
        ...Documents
      }
    }
  `);

  return (
    <Service
      className={styles.wrapper}
      graphicColumnClassName={styles.graphicWrapper}
      title="Riparian Habitat Mitigation Plans"
      text={`
          Pima County’s Riparian Ordinance requires preservation or mitigation of county-designated riparian areas. 

          These plans require careful analysis, a well-thought-out strategy and a full understanding of the many options available to achieve compliance. We use our expertise to achieve compliance while eliminating onerous restrictions on the property.
      `}
      contentPosition="left"
      mobileContentPosition="bottom"
      contentWidth={460}
      icon="RHMP"
      mobileIconSize={55}
      files={files}
    >
      <StaticImage
        src="riparian-area.jpg"
        alt="Desert Riparian Area"
        layout="fullWidth"
        quality={40}
        formats={['auto', 'webp', 'avif']}
        objectFit="cover"
        className={styles.graphic}
      />
    </Service>
  );
}
