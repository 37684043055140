import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Service from '../Service';

export default function RezoningSupport() {
  const files = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          extension: { eq: "pdf" }
          relativeDirectory: { regex: "/RezoningSupport/" }
        }
      ) {
        ...Documents
      }
    }
  `);

  return (
    <Service
      title="Rezoning Support"
      text={`
        We provide rezoning support at a variety of levels. We can provide a full graphics package for an entire rezoning.
        
        With more than 30 maps required in County rezonings, and rezoning booklets exceeding 100 pages, it is critical to coordinate all of the information for ease of review by the municipality. We take all of the information from the team planner, engineer, hydrologist, landscape architect and others and generates clear, consistent graphics for the rezoning book. This leads to an ease of review for the municipality and a certainty that all the information in table, charts, maps and graphics matches.  We can also provide all of the required environmental, landscape and CLS evaluations.
      `}
      contentPosition="right"
      contentWidth={480}
      icon="RezoningSupport"
      iconOffsetTop={-3}
      listIconSize={30}
      listIconOffsetTop={-1}
      listIconOffsetLeft={2}
      files={files}
      inlineViewer
      blueBg
    />
  );
}
