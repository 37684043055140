import React from 'react';
import IconButton from '@components/IconButton';
import * as styles from './DocumentViewer.module.scss';
import * as Icon from './icons';

export default function TopBar({
  file,
  isExpanded,
  setIsExpanded,
  setIsOpen
}) {
  return (
    file && (
      <div className={styles.topBar}>
        {file.name}

        <div className={styles.icons}>
          <IconButton
            icon={Icon.Download}
            size={23}
            tooltipText="Download"
            href={file.url}
            download
          />

          <IconButton
            icon={isExpanded ? Icon.Close : Icon.Expand}
            size={isExpanded ? 23 : 24}
            tooltipText={isExpanded ? 'Close' : 'Expand Document'}
            onClick={() => {
              if (setIsOpen) {
                setIsOpen(!isExpanded);
              }

              setIsExpanded(!isExpanded);
            }}
          />
        </div>
      </div>
    )
  );
}
