// extracted by mini-css-extract-plugin
export const wrapper = "DocumentViewer-module--wrapper--G6ksW";
export const pageImage = "DocumentViewer-module--pageImage--39esG";
export const content = "DocumentViewer-module--content--3PTDB";
export const topBar = "DocumentViewer-module--topBar--2c_e2";
export const icons = "DocumentViewer-module--icons--1BSvI";
export const documentWrapper = "DocumentViewer-module--documentWrapper--_7y_w";
export const error = "DocumentViewer-module--error--3_X7G";
export const document = "DocumentViewer-module--document--2XZXS";
export const loading = "DocumentViewer-module--loading--3rw5c";
export const pdfPage = "DocumentViewer-module--pdfPage--XOHOw";
export const zoomWrapper = "DocumentViewer-module--zoomWrapper--2TKqe";
export const page = "DocumentViewer-module--page--1VXPv";
export const errorWrapper = "DocumentViewer-module--errorWrapper--1dmlP";
export const thumbnailWrapper = "DocumentViewer-module--thumbnailWrapper--jc9Mp";
export const thumbnail = "DocumentViewer-module--thumbnail--3R3h_";
export const current = "DocumentViewer-module--current--3w6VD";
export const embed = "DocumentViewer-module--embed--2RBPO";
export const expanded = "DocumentViewer-module--expanded--3BfFG";
export const imageRenderer = "DocumentViewer-module--imageRenderer--1Tlnc";